import '../assets/css/Navigation-Clean.css'

const Navigation = () => {
    return (
        <>
            <nav style={{ marginBottom: 20}} className="navbar navbar-light">
                <div className="container">
                    <a className="navbar-brand" href={process.env.PUBLIC_URL + "/"}>
                        <img className="d-inline-block align-top" src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/OpenVPN_logo.svg/1920px-OpenVPN_logo.svg.png" alt='OpenVPN Logo' style={{width: 80}}/>
                        <span className="d-none d-xl-flex justify-content-xl-center">OpenVPN Admin UI</span>
                    </a>
                    <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1">
                        <span className="visually-hidden">Toggle navigation</span><span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="container">
                    <div className="collapse navbar-collapse" id="navcol-1">
                        <ul className="navbar-nav">
                            <li className="nav-item"><a className="nav-link" href={process.env.PUBLIC_URL + "/"}>Home</a></li>
                        </ul>
                    </div>
                </div>
            </nav>  
        </>
    )
}

export default Navigation
