import React from 'react'

const UserTable = ({connectedUsers}) => {
  const tableHeaders = ["port", "remote_ip", "city", "country", "long", "lat", "bytes_recv", "bytes_sent", "connected_since", "username"]

  return (
    <div className='container'>
        <h3>Connected users</h3>
        <table className='table table-lg'>
        <thead>
            <tr key={-1}>
            {
                tableHeaders.map((header, index) => 
                    <th key={index} scope='col' idx={index}>{header}</th>
                )
            }
            </tr>
        </thead>
        <tbody>
            {
                connectedUsers.map((user, userIndex) => 
                    <tr key={userIndex} idx={userIndex}>
                        {
                            tableHeaders.map((header, attributeIndex) => 
                                <td key={attributeIndex} idx={attributeIndex}>{user[header]}</td>
                            )
                        }
                    </tr>
                )
            }
        </tbody>
        </table>
    </div>
  )
}

export default UserTable