import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Login from './components/Login';
import HomePage from './components/HomePage';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'


function ProtectedRoute({ children }) {
  let location = useLocation();
  
  if (sessionStorage.getItem("token") !== process.env.REACT_APP_TOKEN) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

function App() {
  return (
    <BrowserRouter>
    <Navigation />
    <Routes>
        <Route path="/" exact element={ <ProtectedRoute><HomePage /></ProtectedRoute>}/>
        <Route path="/login" exact element={ <Login />}/>
    </Routes>
    <Footer />
    </BrowserRouter>
  );
}

export default App;
