import React from 'react'
import MyDropdown from './Dropdown'
import UserTable from './UserTable';
import UsersMap from './UsersMap';
import { useState, useEffect } from 'react'

const HomePage = () => {
  const [state, setState] = useState([])
  const [selectedOption, setSelectedOption] = useState('');
  const [connectedUsers, setConnectedUsers] = useState([])

  const servers = [
    { key: '1', value: '195.201.5.83', label: 'Germany' },
    { key: '2', value: '65.108.156.207', label: 'Helsinki' },
    { key: '3', value: '178.154.220.63', label: 'Moscow' },
  ]

  const fetchConnetedUsers = async (serverHost) => {
    const res = await fetch(`http://`+ serverHost + `:49208/analytics/sessions`)
    const connectedUsers = await res.json()
    return connectedUsers
  }

  const fetchState = async (serverHost) => {
    const res = await fetch(`http://`+ serverHost + `:49208/analytics/stats`)
    const state = await res.json()
    return state
  }

  const handleSelect = async (selectedValue) => {
    console.log(`Selected value: ${selectedValue}`);
    setSelectedOption(selectedValue);
    const state = await fetchState(selectedValue)
    setState(state)
    
    const connectedUsers = await fetchConnetedUsers(selectedValue)
    setConnectedUsers(connectedUsers)
  };

  useEffect(() => {
    const storedValue = sessionStorage.getItem('selectedServer');
    const getState = async () => {
      // Load selected value from session storage if it exists
      if (storedValue) {
        setSelectedOption(storedValue);
        const state = await fetchState(storedValue)
        setState(state)

        const connectedUsers = await fetchConnetedUsers(storedValue)
        setConnectedUsers(connectedUsers)
      }
    }
    getState()
  }, [])

  return (
    <>  
        <MyDropdown servers={servers} onSelect={handleSelect} />
        <br />
        {
          
            selectedOption !== '' ? 
            <>
            <div className='container'>
            <h3>VPN server summary</h3>
            <p><b>Up since: </b>{state.up_since}</p>
            <p><b>Connect status: </b>{state.connect_status}</p>
            <p><b>Local IP: </b>{state.local_ip}</p>
            <p><b>Mode: </b>{state.mode}</p>
            <p><b>№ clients: </b>{state.nclients}</p>
            <p><b>Bytes in: </b>{state.bytesin}</p>
            <p><b>Bytes out: </b>{state.bytesout}</p>
            </div>
            <UserTable connectedUsers={connectedUsers}/> 
            <UsersMap connectedUsers={connectedUsers}/>
            </>
           : <div className='container'><p>Please, select server above</p></div>
           
        }
        
    </>
  )
}

export default HomePage
