import '../assets/css/bootstrap.min.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react'

const Login = () => {
    const navigate = useNavigate();
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
   
    const handleLogin = (e) => {
        setLogin(e.target.value)
    }
    const handlePassword = (e) => {
      setPassword(e.target.value)
    }
    const handleSubmit = (e) => { 
      e.preventDefault()
      if (login === process.env.REACT_APP_LOGIN && password === process.env.REACT_APP_PASSWORD) {
        sessionStorage.setItem('token', process.env.REACT_APP_TOKEN)
        navigate('/')
      } else {
        alert("invalid credentials")
      }
    }

    return (
        <div className="container">
            <form className="form-inline" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="inputLogin">Login</label>
                    <input onChange={handleLogin} value={login} type="text" className="form-control" id="inputLogin" aria-describedby="emailHelp" placeholder="Enter login" />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input onChange={handlePassword} value={password} type="password" className="form-control" id="inputPassword" placeholder="Password" />
                </div>
                <div className="form-group">
                    <button className='btn btn-primary' style={{marginTop: '10px'}} type='submit'>Submit</button>
                </div>
                
            </form>
        </div>
    )
}

export default Login
