import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const UsersMap = ({connectedUsers}) => {
  return (
        <div className='container'>
            <h3>Map of the users</h3>
            <MapContainer center={[60.9264406,76.4353955]} zoom={5} style={{ height: '50vh', width: '100wh' }}>
            <TileLayer 
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                connectedUsers.map((user, userIndex) => 
                <Marker position={[user.long, user.lat]}>
                    <Popup>
                        {user.username}<br />{user.connected_since}
                    </Popup>
                </Marker>
                )
            }
            </MapContainer>
        </div>
  )
}

export default UsersMap