import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';

const MyDropdown = ({ servers, onSelect }) => {
    const [selectedValue, setSelectedValue] = useState('');
    const handleSelect = (eventKey) => {
        setSelectedValue(eventKey);
        sessionStorage.setItem('selectedServer', eventKey);
        onSelect(eventKey);
    };

    useEffect(() => {
        // Load selected value from session storage if it exists
        const storedValue = sessionStorage.getItem('selectedServer');
        if (storedValue) {
          setSelectedValue(storedValue);
        }
      }, []);

    return (
        <div className='container'>
            <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedValue || 'Select a Server'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {servers.map((option) => (
                        <Dropdown.Item key={option.key} eventKey={option.value}>
                            {option.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default MyDropdown