import '../assets/css/bootstrap.min.css'

const Footer = () => {
    return (
        <footer style={{marginTop: 50}}>
            <div className="container">
                <hr />
                <p>
                    Created by: Web of Russia
                </p>
            </div>
        </footer>
    )
}

export default Footer
